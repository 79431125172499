import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import MenuBar from './MenuBar';
import LandingPage from './LandingPage';
import ChatDemo from './ChatDemo';
import Founders from './Founders';
import Console from './Console';
import Policies from './Policies'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function App(){
  var user;
  async function checkUserAuthenticated() {
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      user = undefined;
    }
  }

  function ScrollToTopOnRouteChange() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
      document.documentElement.setAttribute('data-iscroll-anchor', 'false');
    }, [pathname]);
    return null;
  }

  return (
    <Router>
      <div className="App">
        <MenuBar userAuthenticated={typeof(user) !== 'undefined'}/>
          <Routes>
            <Route path="/" element={<LandingPage/>} />
            <Route path="/demo" element={<ChatDemo/>} />
            <Route path="/about_us" element={<Founders/>} />
            <Route path="/console" element={<Console/>} />
            <Route path="/policies" element={<Policies/>} />
          </Routes>
          <ScrollToTopOnRouteChange />
      </div>
    </Router>
  );
}

export default App;