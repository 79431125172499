import '@aws-amplify/ui-react/styles.css';
import React from 'react';
import './LandingPage.css';
import { Link } from 'react-router-dom';
import Timeline from './Timeline';

function LandingPage() {
  function handleClick() {
    window.scrollTo(0, 0);
  }

  return(
    <div>
      <div className="hero-image">
        <div className="hero-text">
          <h1>
            <span style={{color: 'var(--white-1)'}}>Nuanced</span>
            <span style={{color: 'var(--blue-4)'}}>AI</span>
          </h1>
          <p>Scalable. Cost-Effective. Nuanced.</p>
          <p>Content moderation utilizing the power of AI LLMs.</p>
        </div>
      </div>
      <div className="content">
        <br></br>
        <p>Recent advances in AI language models mean that now, more than ever before, AI is capable of detecting nuance in complex situations, making it possible to gauge intent and identify potentially harmful content.</p>
        <br></br>
        <p>NuancedAI offers an unprecedentedly cost-effective solution for online content moderation by combining the power of AI language models with a community-centric approach.</p>
        <br></br>
      </div>
      <Timeline/>
      <div className="content">
        <br></br>
        <p>We strive to make the internet safer and more inclusive by using these AI language models to speed up and improve the accuracy of content moderation. Our platform, still in development, will scan images, text, and video, to provide valuable insights and action-items to human moderators, such as post-removals, user-bans, and more.</p>
        <br></br>
        <p>Our focus on user-customizability helps to ensure that our platform aligns with the values of each community it is utilized in. We leave the decision of which AI language model to use up to the user and offer a selection of both closed and open-source models.</p>
        <br></br>
        <p>NuancedAI’s content moderation platform will improve the safety and inclusivity of online communities by enabling content moderators to seamlessly leverage the power of modern LLMs.</p>
        <br></br>
      </div>
    </div>
  );
}

export default LandingPage;