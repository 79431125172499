import React, { useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';

import './MenuBar.css';
//import Authenticator from './Authenticator';
import { Authenticator } from '@aws-amplify/ui-react';

function MenuBar() {
  const [showAuthenticator, setShowAuthenticator] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authenticatorRef = useRef(null);

  useEffect(() => {
    checkAuthStatus();

    // Listens for a sign in event, changes button if so
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        setIsAuthenticated(true);
        setShowAuthenticator(false);
      }
    });
  }, []);

  async function checkAuthStatus() {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  }

  async function signIn() {
    setShowAuthenticator(true);
  }

  const handleClickOutsideAuthenticator = (event) => {
    console.log(authenticatorRef.current);
    console.log(event.target);
    if (authenticatorRef.current && !authenticatorRef.current.contains(event.target)) {
      setShowAuthenticator(false);
    }
  };

  useEffect(() => {
    if (showAuthenticator) {
      document.addEventListener('mousedown', handleClickOutsideAuthenticator);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideAuthenticator);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideAuthenticator);
    };
  }, [showAuthenticator]);


  return (
    <div>
      <div className="menuBar">
        <Link to="/" className="logoLink">
          <img src="favicon.ico" alt="NuancedAI Logo" className="logoImg" />
          NuancedAI
        </Link>
        <Link to="/about_us">Contact Us</Link>

        {isAuthenticated ? (
          <button id="signOutButton" onClick={signOut}>Sign Out</button>
        ) : (
          <button id="signInButton" onClick={signIn}>Sign In</button>
        )} 
      </div>

      {showAuthenticator && (
        <div ref={authenticatorRef} className="authenticator">
          <Authenticator />
        </div>
      )}
    </div>
  );
}

export default MenuBar;
