import '@aws-amplify/ui-react/styles.css';
import React from 'react';
import './Founders.css';

function Profile({ imgSrc, name, bio, contact, linkedin }) {
  return (
    <div className="profile">
      <div className="profile-img-container">
        <img src={imgSrc} alt={`${name}'s profile`} />
      </div>
      <div className="profile-name">{name}</div>
      <div className="profile-bio">{bio}</div>
      <div className="profile-contact">
        {contact.map((info, index) => (
          <div key={index}>{info}</div>
        ))}
        {linkedin && (
          <a
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-link"
          >
            LinkedIn
          </a>
        )}
      </div>
    </div>
  );
}

function Founders() {
  const profiles = [
    {
      imgSrc: 'george_headshot.png',
      name: 'George N. Young',
      bio: "CTO of NuancedAI and full-stack software engineer at ServiceNow in Santa Clara. Former back-end engineer for United Launch Alliance. Absolutely fascinated by AI and have spent countless hours theorizing about the impact it will have on society - I would describe myself as cautiously optimistic in that regard, and strive to use it to make the world a better place.",
      contact: ['Email: gny@young.tech'],
      linkedin: 'https://www.linkedin.com/in/georgenicholasyoung/',
    },
    {
      imgSrc: 'emerson_headshot.jpg',
      name: 'Emerson Trout',
      bio: 'I am currently a Finance Specialist at iFixit in San Luis Obispo and have previously worked with multiple early to late-stage startups working on building a new ideas all the way to acquisition. I love technology and the value that it can bring to the world. AI has been one of my favorite topics for many years, and I am ecstatic to be doing my part to leverage its power to make lives better.',
      contact: ['Email: troutemerson@gmail.com'],
      linkedin: 'https://www.linkedin.com/in/emerson-trout/',
    },
  ];

  return (
    <div className="container">
      {profiles.map((profile, index) => (
        <Profile key={index} {...profile} />
      ))}
    </div>
  );
}

export default Founders;