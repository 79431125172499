import React, { useState, useEffect, useRef } from 'react';
import Widget from './Widget';
import DOMPurify from 'dompurify';
import './PostsWidget.css';

const RecentPostsWidget = () => {
    const [lastTenPosts, setLastTenPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [contextMenu, setContextMenu] = useState({
        show: false,
        x: 0,
        y: 0,
        username: '',
        user_id_num: '',
    });

    useEffect(() => {
        callLambdaFunction(currentPage);
    }, [currentPage]);

    const callLambdaFunction = async (page) => {
        try {
            const response = await fetch(`https://1xp7gsmd60.execute-api.us-east-2.amazonaws.com/dev?page=${encodeURIComponent(page)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            var lambdaResponse = JSON.parse(data.body);
            setTotalPages(Math.ceil(lambdaResponse.totalPosts / 10) || 1);
            if(lambdaResponse.posts){
                parsePosts(lambdaResponse.posts);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);
    };
    const handleNextPage = () => {
        setCurrentPage(currentPage => (currentPage < totalPages ? currentPage + 1 : currentPage));
    };
    const handlePageNumberClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const parsePosts = async (posts) => {
        const postsWithContent = await Promise.all(posts.map(async (post) => {
            try {
                var content = await fetchPostById(post.post_id);
                post.content = DOMPurify.sanitize(content.content);
                post.user_id_num = content.user_id_num;
                return post;
            } catch (error) {
                console.error('Error fetching content:', error);
                post.content = `Failed to parse post ${post.post_id}.`;
                return post;
            }
        }));

        setLastTenPosts(postsWithContent);  // Update state with the posts that now include content
    };

    const fetchPostById = async (postId) => {
        let url = 'https://mastodon.young.tech/api/v1/statuses/' + postId;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer hnhmnYwPpX0MHjScErryO2J4DU0E9ohQkwaJ3zPNzqQ',
                },
            });

            if (!response.ok) {
                throw new Error(`Error fetching post: ${response.statusText}`);
            }

            const postData = await response.json();
            return {
                "content": postData.content,
                "user_id_num": postData.account.id
            };
        } catch (error) {
            console.error('Error:', error);
            return `Failed to fetch post with id ${postId}.`;
        }
    };

    const handleButtonOnClick = (post_id, user_id) => {
        window.location.href = "https://mastodon.young.tech/@" + user_id + "/" + post_id;
    }
    const handleButtonOnMouseDown = (event, post_id, user_id) => {
        // Check if the middle mouse button is clicked
        if (event.button === 1) {
            // Open the URL in a new tab
            window.open(`https://mastodon.young.tech/@${user_id}/${post_id}`, '_blank', 'noopener,noreferrer');
        }
    }

    const handleRowClick = (event, username, user_id_num, instance_fqdn) => {
        event.preventDefault();
        setContextMenu({
            show: true,
            x: event.clientX,
            y: event.clientY,
            username: username,
            user_id_num: user_id_num,
            instance_fqdn: instance_fqdn
        });
    };

    const contextMenuRef = useRef(null);

    const handleCloseContextMenu = () => {
        setContextMenu({ ...contextMenu, show: false });
    };

    const handleClickOutsideContextMenu = (event) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            handleCloseContextMenu();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideContextMenu);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideContextMenu);
        };
    }, []);


    const ContextMenu = React.forwardRef(({ show, x, y, username, user_id_num, instance_fqdn, onClose }, ref) => {
        if (!show) return null;

        const style = {
            position: 'absolute',
            top: y,
            left: x,
        };

        return (
            <div ref={ref} style={style} className="context-menu">
                <table id="contextMenuTable">
                    <caption>@{username}@{instance_fqdn}</caption>
                    <tr onClick={(e) => 
                        window.open(`https://${instance_fqdn}/admin/accounts/${user_id_num}/action/new?type=none`, '_blank', 'noopener,noreferrer')}>
                        Send a warning
                    </tr>
                    <tr onClick={(e) => 
                        window.open(`https://${instance_fqdn}/admin/accounts/${user_id_num}/action/new?type=disable`, '_blank', 'noopener,noreferrer')}>
                        Freeze account
                    </tr>
                    <tr onClick={(e) => 
                        window.open(`https://${instance_fqdn}/admin/accounts/${user_id_num}/action/new?type=sensitive`, '_blank', 'noopener,noreferrer')}>
                        Mark account as senstitive
                    </tr>
                    <tr onClick={(e) => 
                        window.open(`https://${instance_fqdn}/admin/accounts/${user_id_num}/action/new?type=silence`, '_blank', 'noopener,noreferrer')}>
                        Silence account
                    </tr>
                    <tr onClick={(e) => 
                        window.open(`https://${instance_fqdn}/admin/accounts/${user_id_num}/action/new?type=suspend`, '_blank', 'noopener,noreferrer')}>
                        Suspend account
                    </tr>
                </table>
            </div>
        );

        function handleAction(path) {
            window.location.href = `/temporary/${path}`;
            onClose();
        }
    });

    return (<Widget title="Watchtower" content={
        <>
            <table id="watchtower">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Post</th>
                        <th>Severity</th>
                        <th>Explanation</th>
                    </tr>
                </thead>    
                <tbody>
                    {lastTenPosts.map((post) => (
                        <tr key={post.post_id} onClick={(e) => handleRowClick(e, post.user_id, post.user_id_num, "mastodon.young.tech")}>
                            <td>{post.user_id ? <a href={`https://mastodon.young.tech/@${post.user_id}`}>{post.user_id}</a> : 'N/A'}</td>
                            <td>
                                <button 
                                    className="postsWidget" 
                                    onClick={() => handleButtonOnClick(post.post_id, post.user_id)}
                                    onMouseDown={(event) => handleButtonOnMouseDown(event, post.post_id, post.user_id)}>
                                    <div dangerouslySetInnerHTML={{__html: post.content}}/>
                                </button>
                            </td>
                            <td>{post.nai_rating || 'N/A'}</td>
                            <td>{post.nai_explanation || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {lastTenPosts.length > 0 && (
                <div className="pagination">
                    <button className="arrow-button" onClick={handlePreviousPage} disabled={currentPage === 1}>
                        <i className="fas fa-arrow-left"></i>
                    </button>
                    {[...Array(totalPages).keys()].map(n => (
                        <button key={n + 1} onClick={() => handlePageNumberClick(n + 1)}>{n + 1}</button>
                    ))}
                    <button className="arrow-button" onClick={handleNextPage} disabled={currentPage === totalPages}>
                        <i className="fas fa-arrow-right"></i>
                    </button>
                </div>
            )}
            <ContextMenu
                ref={contextMenuRef}
                show={contextMenu.show}
                x={contextMenu.x}
                y={contextMenu.y}
                username={contextMenu.username}
                user_id_num={contextMenu.user_id_num}
                instance_fqdn={"mastodon.young.tech"}
                onClose={handleCloseContextMenu}
            />
        </>
    }></Widget>);
};

export default RecentPostsWidget;
