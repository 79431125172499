import React from 'react';
import './Timeline.css';

function Timeline() {
  return (
    <div className="timeline-container">
      <div className="timeline">
        <div className="timeline-item">
          <div className="timeline-date">February 2023</div>
          <div className="timeline-content">
            <h2>NuancedAI is founded</h2>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">April 2023</div>
          <div className="timeline-content">
            <h2>Moderator API Deployed</h2>
            <p>Our moderator API can determine if a post breaks site rules, contains profanity or NSFW content, and more - for just fractions of a penny.</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">May 2023</div>
          <div className="timeline-content">
            <h2>Native Mastodon instance integration</h2>
            <p>Open source and federated communities like Mastodon have long relied on volunteers for content moderation. Our platform will seamlessly integrate with Mastodon instances to increase the efficiency and productivity of content moderators.</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">Summer 2023</div>
          <div className="timeline-content">
            <h2>Web Moderation Console</h2>
            <p>The moderator's best friend - our web-based moderation console will provide moderators with helpful data about posts that may have broken policy rules, offer content-removal and user-ban recommendations, and provide valuable graphs and insights to help moderators do what they do best.</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-date">Fall 2023</div>
          <div className="timeline-content">
            <h2>Image and video scanning</h2>
            <p>Not just text - NuancedAI will scan both images and video for policy-violating content in your community.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;