import React, { useState, useEffect } from 'react';
import Widget from './Widget';

const InstanceInfoWidget = ({ instanceUrl }) => {
    const [instanceInfo, setInstanceInfo] = useState("");

    useEffect(() => {
         if (instanceInfo.length === 0) {
                fetch((instanceUrl + '/api/v1/instance'), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => response.json())
                .then((data) => {
                    FormatInstanceInfo(data);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            }
    }, [instanceUrl]);

    const FormatInstanceInfo = (data) => {
        // Extract the rules from the data variable
        const rulesList = data.rules.map((rule, index) => (
            <div key={rule.id} style={{ marginLeft: '20px' }}>
                {index + 1}. {rule.text.split('\r\n').map((text, i) => 
                    <span key={i}>
                        {text}
                        <br />
                    </span>
                )}
            </div>
        ));

        var relevantData = [
            <div key="title">Instance name: {data.title}</div>,
            <div key="userCount">User count: {data.stats.user_count}</div>,
            <div key="registration">New registrations: {data.registrations ? "Enabled" : "Disabled"}</div>,
            <div key="instanceRules">Instance rules:</div>,
            ...rulesList
        ];

        setInstanceInfo(relevantData);
    }

    return <Widget title="Instance Info" content={instanceInfo} />;
}

export default InstanceInfoWidget;
