import React from 'react';
import './Widget.css';

function Widget({ title, content }) {
  return (
    <div className="widget">
      <h2>{title}</h2>
      <div className="widget-content">{content}</div>
    </div>
  );
}

export default Widget;
