import React, { useState } from 'react';
import './ChatDemo.css';

function ChatDemo(){
    const [inputValue, setInputValue] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [selectedModel, setSelectedModel] = useState('gpt-3.5-turbo');
    const [isLoading, setIsLoading] = useState(false);
    
    const models = ['GPT-4', 'LLaMa-65B', 'Dolly 2.0', 'Vicuna-13B', 'Bard'];
    const getModelVariableName = (displayName) => {
        switch (displayName) {
            case 'GPT-4':
                return 'gpt-4';
            default:
                return '';
        }
    };
    const handleModelSelect = (model) => {
        if (model !== 'GPT-4') {
            return;
        }
        setSelectedModel(getModelVariableName(model));
    }

    const [options, setOptions] = useState([
        { label: 'Do not talk about fight club.', isEnabled: false },
        { label: 'All text MUST include something to do with dinosaurs.', isEnabled: false },
        { label: 'You must never utter the name of the dark lord (Voldemort).', isEnabled: false },
    ]);

    const handleToggle = (index) => {
        const newOptions = [...options];
        newOptions[index].isEnabled = !newOptions[index].isEnabled;
        setOptions(newOptions);
    };

    const ChatBubble = () => {
        return (
            <div className="chat-bubble bot">
            <div className="typing-indicator">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
            </div>
        );
    };

    const getChatDemo = function(){ return(<>
        <div className="chat_demo">
            <div className="instruction_text">
                <br></br>
                <h1>Step 1: Choose your model</h1>
                <p>Our platform is powered by foundational models, but you choose which one. We offer a number of closed and open-source models to best support your community's platform.</p>
                <br></br>
            </div>
            <div className="model-selector">
                {models.map((model) => {
                    const modelVariableName = getModelVariableName(model);
                    const isLowerOpacity = !modelVariableName;
                    return (
                        <button
                            key={model}
                            className={`model-selector-button ${selectedModel === modelVariableName ? 'selected' : ''} ${isLowerOpacity ? 'lower-opacity' : ''}`}
                            onClick={() => handleModelSelect(model)}
                            title={isLowerOpacity ? 'Coming soon!' : ''}
                        >
                            {model}
                        </button>
                    );
                })}
            </div>

            <div className="instruction_text">
                <br></br>
                <h1>Step 2: Set your content policy</h1>
                <p>Not a machine learning engineer? No problem! You can use plain english to tailor your moderation policy. Continue to Step 3 to use our default ruleset which looks for violent or hateful content, or add in some complexity by enabling some of these silly rules.</p>
                <br></br>
            </div>
            <div className="policy-generator">
                {options.map((option, index) => (
                    <div key={index} className="option-item">
                    <span className="option-label label-text">{option.label}</span>
                    <div className="toggle-switch-container" style={{ marginLeft: 'auto' }}>
                        <label className="toggle-switch">
                        <input
                            type="checkbox"
                            checked={option.isEnabled}
                            onChange={() => handleToggle(index)}
                        />
                        <span className="slider"></span>
                        </label>
                    </div>
                    </div>
                ))}
            </div>

            <div className="instruction_text">
                <br></br>
                <br></br>
                <h1>Step 3: See your policy in action</h1>
                <p>Use this tool to see your rules in practice. Write a test message, and the AI moderator will judge the text based on the ruleset you defined in Step 2.</p>
            </div>
            <div className="chat-container">
                <div className="chat-messages">
                    {chatMessages.map((msg, index) => (
                    <div
                        key={index}
                        className={`chat-bubble ${msg.isUserMessage ? 'user' : 'bot'}`}
                    >
                        {msg.message}
                    </div>
                    ))}
                    {isLoading && <ChatBubble />}
                </div>
            </div>
            <div className="chatbox">
                <textarea
                    onInput={handleInput}
                    placeholder="Enter text..."
                    value={inputValue}
                    rows={1} maxLength={50000}
                    onChange={handleInputChange}
                    onKeyDown={handleInputChange} />
            </div>
            
            <div className="instruction_text">
                <br></br>
                <h1>Step 4: Integration into your platform</h1>
                <p>NuancedAI is compatible with Mastodon instances, Discord servers, and more. You can deploy NuancedAI directly into your platform, or manually call our API instead.</p>
                <div className="imageContainer">
                    <img src="mastodon_logo.png" alt="Mastodon Logo"/>
                    <img src="discord_logo.png" alt="Discord Logo"/>
                </div>
                <p>Once integrated, NuancedAI will continuously scan text, images, and even videos posted on your platform, flag violations of your content moderation policy, recommend actions such as content removal or user bans, and more.</p>
                <br></br>
            </div>
        </div>
    </>)};

    const handleInputChange = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && !isLoading) {
            //Prevent default key behavior (inserting a line break)
            event.preventDefault(); 
            handleSubmit();
        } else {
            setInputValue(event.target.value);
        }
    };

    const handleSubmit = () => {
        setInputValue('');
        setIsLoading(true);

        let rules = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].isEnabled) {
                rules.push(options[i].label);
            }
        }

        setChatMessages((prevMessages) => [
            ...prevMessages,
            { message: inputValue, isUserMessage: true }
        ]);

        fetch('https://p3bxxp6djf.execute-api.us-east-2.amazonaws.com/dev', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                model: selectedModel,
                prompt: inputValue,
                moderation_policy: rules,
                natural_response: 1
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            setIsLoading(false);
            setChatMessages((prevMessages) => [
                ...prevMessages, { 
                    message: data.body.substring(1, data.body.length - 1),
                    isUserMessage: false 
                },
            ]);
        }).catch((error) => {
            setIsLoading(false);
            console.error('Error:', error);
        });
    };

    const handleInput = (event) => {
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + 'px';
    };

    return(getChatDemo());
}

export default ChatDemo;