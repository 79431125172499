import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function BarGraph({ labels, dataValues }) {
    const maxDataValue = Math.max(...dataValues) + 20;

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Data',
                data: dataValues,
                backgroundColor: ctx => {
                    const chart = ctx.chart;
                    const yScale = chart.scales['y'];

                    if (!yScale || !isFinite(yScale.bottom) || !isFinite(yScale.top)) {
                        console.warn("yScale values are not valid");
                        return 'white';
                    }

                    const gradient = chart.ctx.createLinearGradient(0, yScale.bottom, 0, yScale.top);
                    gradient.addColorStop(0, 'white');
                    gradient.addColorStop(1, '#0F2B44');
                    return gradient;
                },
                categoryPercentage: 0.8,
                barPercentage: 0.7,
                order: 1,
            },
            {
                data: new Array(labels.length).fill(maxDataValue),
                backgroundColor: '#878787',
                categoryPercentage: 0.8,
                barPercentage: 0.7,
                order: 2,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                max: 120,
            },
            x: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            bar: {
                borderRadius: {
                    topLeft: 25,
                    topRight: 25,
                    bottomLeft: 25,
                    bottomRight: 25
                }
            },
        },
    };

    return <Bar data={data} options={options} />;
}

export default BarGraph;
