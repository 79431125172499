import React from 'react';
import Widget from './components/Widget';
import BarGraph from './components/BarGraph';
import InstanceInfoWidget from './components/InstanceInfoWidget';
import RecentPostsWidget from './components/PostsWidget';
import './Console.css';

function Console(){
    return (
        <div className="console">
            <div className="widget-grid">
                <RecentPostsWidget />
                <InstanceInfoWidget instanceUrl="https://mastodon.young.tech" className="widget" />
                <Widget 
                    title="Content Violations Over Time" 
                    content={
                        <BarGraph
                            labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']}
                            dataValues={[80, 55, 100, 30, 90, 85, 50]}
                        />
                    } 
                    className="widget" 
                />
                <Widget title="Federated Domains" content={<div>Sample data</div>} className="widget" />
                <Widget title="Top Offenders" content={<div>Sample data</div>} className="widget" />
                <Widget title="Flagged Posts" content={<div>Sample data</div>} className="widget" />
                <Widget title="User Reports" content={<div>Sample data</div>} className="widget" />
            </div>
        </div>
    );
}

export default Console;



